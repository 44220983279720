<!--
 * @Author: wxy
 * @Date: 2022-10-21 09:30:32
 * @LastEditTime: 2022-10-21 10:45:09
 * @Description: 查看供应商
-->
<template>
  <div>
    <div class="header">
      <ds-header title="查看供应商" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <div class="content">
      <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-model-item label="供应商名称"> {{ form.supplierName }} </a-form-model-item>
        <a-form-model-item label="供应商适用项目"> {{ form.projectNames }} </a-form-model-item>
        <a-form-model-item label="供应商LOGO">
          <img :src="form.supplierLogo" alt="" class="img" />
        </a-form-model-item>
        <a-form-model-item label="供应商简介">
          <span v-html="form.supplierContent" class="notice"></span>
        </a-form-model-item>
        <div v-for="(item, index) in form.supplierLabelList">
        <a-form-model-item
          :label="index === 0 ? '供应商分类' : ' '"
          :colon="index == 0 ? true : false"
          :span="24"
          placeholder="请输入供应商分类，不超过5个字符"
          :prop="'supplierLabelList.' + index + '.productLabel'"
          :rules="{
            message: '请输入供应商分类，不超过5个字符',
            trigger: 'change',
          }"
        >
          <div
            style="
              display: flex;
              justify-content: flex-start;
            "
          >
          {{ item.productLabel }}
          </div>
        </a-form-model-item>
      </div>
        <a-form-model-item label="状态"> {{ form.supplerState === 0 ? "已启用" : "已禁用" }} </a-form-model-item>
        <a-form-model-item label="创建人"> {{ form.createName }} </a-form-model-item>
        <a-form-model-item label="创建时间">{{ form.gmtCreate }}</a-form-model-item>
        <a-form-model-item label="最后修改人"> {{ form.modifiedName || "暂无" }} </a-form-model-item>
        <a-form-model-item label="最后修改时间"> {{ form.gmtModified || "暂无" }} </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/entrepreneurClubManagement/serverManagement";
export default {
  name: "detailSupplier",
  data() {
    return {
      form: {

      },
    };
  },
  created() {
    this.getDetailList();
  },
  methods: {
    // 获取详情
    async getDetailList() {
      try {
        const res = await api.supplierDetail({ supplierId: this.$route.query.id });
        if (res.code === "200") {
          this.form = res.data;
          let arr = []
          if(res.data.supplierLabelList){
            for (let i = 0; i < res.data.supplierLabelList.length; i++) {
              const item = res.data.supplierLabelList[i];
              arr.push({productLabel:item})
            }
          }else{
            arr.push({productLabel:''})
          }
          console.log(arr);
          this.form.supplierLabelList = arr
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.content {
  width: 70%;
  margin: 10px auto;
}
.img{
  width: 126px;
  height: 126px;
}
::v-deep  table {
     border: none;
     border-collapse: collapse;
}
::v-deep  table td{
     border: 1px solid #ccc;
     padding: 3px 5px;
     min-width: 50px;
     height: 20px;
}
::v-deep  table th {
     border-right: 1px solid #ccc;
     border-bottom: 2px solid #ccc;
     text-align: center;
     background-color: #f1f1f1;
     border: 1px solid #ccc;
     padding: 3px 5px;
     min-width: 50px;
     height: 20px;
}
 
</style>